<template>
    <div class="box-content">
      <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center">
          <img src="@/assets/img/icon-app.svg" height="90" />
          <h5 class="my-5 w-75">{{ $t("app_download.title") }}</h5>
          <p class="w-75 mb-5">{{ $t("app_download.description") }}</p>
          <button class="new-button" @click="openApp">
            {{ $t('app_download.button') }}
          </button>
       </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "download-app",
    methods: {
      detectOs() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
          return "Android";
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }

        return "Desktop";
      },
      redirectIfMobile() {
        const os = this.detectOs();

        if(os == 'Desktop') {
          window.location.href = process.env.VUE_ADM_PAGE;
          return;
        };

        this.openApp();
      },
      openApp() {
        let redirectCode = '';

        if(typeof this.$route.query.rdr != "undefined") {
          redirectCode = this.$route.query.rdr;
        }

        const os = this.detectOs();
        let deepLink = `greenn://login/${redirectCode}`;
        window.location.href = deepLink;

        setTimeout(function() {
          const appStoreUrl = 'https://apps.apple.com/us/app/greenn-adm/id6450507558';
          const playStoreUrl = 'https://play.google.com/store/apps/details?id=br.com.greenn&hl=pt_BR&gl=US';
          window.location.href =  os == 'Android' ? playStoreUrl : appStoreUrl;
        }, 2500);
      },
    },
    created() {
      this.redirectIfMobile();
    }
  };
  </script>
  
  <style scoped>
  .logo-greenn img {
    height: 60px;
  }
  .h-100 {
    height: 100vh!important;
  }
  h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;

    color: #002522;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #81858E;
  }
  .new-button {
    width: 140px;
    height: 55px;

    background: linear-gradient(88.77deg, #92C127 -73.82%, #008C95 98.93%);
    border-radius: 50px;
    border: none;

    color: white;

    font-weight: 600;
  }
</style>
  